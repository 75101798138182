import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import ig from "../assets/ig.png"
import yt from "../assets/yt.png"
import twt from "../assets/twt.png"
import tiktok from "../assets/tiktok.png"
import fb from "../assets/fb.png"
import wa from "../assets/wa.png"
import Qrcode from "../assets/qr-code.png"
import appstore from "../assets/apps-store.png"
import playstore from "../assets/google-play-badge.png"
import menu from "../assets/menu.png"
import { Link, Element } from 'react-scroll'
import Config from '../../Config'

const Home = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const siteDesc = data.site.siteMetadata.description
  const assets = 'https://assets.mingjaya.com/images'
  const [items, setItems] = useState();

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(Config.SERVERAPI + 'home');
      const jsonData = await response.json()
      setItems(jsonData)
    }
    fetchData();
  }, [])

  const settings = {
    className: 'slider-brand',
    speed: 500,
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    rows: 2,
    arrows: true,
    dots: false,
  };

  const store = {
    className: 'slider-store lg:pl-1.5',
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    appendDots: dots => (
      <div>
        <div className="slick-dotsmj">
          <ul> {dots} </ul>
        </div>
      </div>
    ), 
  };

  const label = (data) => {
    let label = {}
    if (data === "1") {
      label = {
        classLabel: "new",
        textLabel: "New"
      }
    } else if (data === "2") {
      label = {
        classLabel: "best",
        textLabel: "Best"
      }
    } else if (data === "3") {
      label = {
        classLabel: "hot",
        textLabel: "Hot"
      }
    }
    return label
  }

  const handleOnClick = () => {
    document.getElementById("burger").checked = false;
  };

  return ( 
    <Layout data={items}>
      <SEO title={siteTitle} description={siteDesc} />
      {items ? 
      <>
        <div>
          <nav className="global-sticky-nav py-4 border-btm-header">
            <div className="wrap-center">
              <Link to="/" className="logo-landing-page cursor-pointer">
                <img className="logo-landing" src={items.header.logo} width="156" height="36" alt="Logo Ming Jaya" />
              </Link>
              <input id="burger" type="checkbox" className="invisible absolute" />
              <label htmlFor="burger">
                <img src={menu} alt="Ming Jaya Sejahtera" className="lg:hidden"/>
              </label>
              <div className="menus text-white opacity-87">
                {items.header.data.map((header, i) => {
                  return (
                    <li key={i} className="list-none cursor-pointer">
                      <Link activeClass="active" className="shadow-none" to={header.link} onClick={handleOnClick} spy={true} smooth={true} duration={10}>{header.title}</Link>
                    </li>
                    )
                  })
                }
              </div>
            </div>
          </nav>
        </div>
        <Element name="beranda" className="element" onClick={handleOnClick} onKeyDown={handleOnClick}>
          <div className="main-banner relative bg-no-repeat bg-cover bg-center flex items-center top-12 overflow-x-hidden" style={{backgroundImage: `url(${assets}/bg-banner.webp)`}}>
            <div className="main-banner-content text-white w-text-main-banner py-20 pl-4 lg:py-0 lg:pl-0">
              <p className="second-orange text-2xl lg:text-4xl font-bold main-font">The Number 1 Online Watch Store in Indonesia</p>
              <span className="block pt-4 text-sm lg:text-base">Kami selalu berkomitmen memberikan pelayanan terbaik dalam menyediakan jam tangan berkualitas dengan harga menarik.</span>
              <span className="block pt-4 text-sm lg:text-base">Kenyamanan, keamanan, dan kepuasan customer menjadi energi kami untuk terus menempatkan customer sebagai prioritas utama.</span>
            </div>
          </div>
          <div className="wrap-center overflow-x-hidden">
            <div className="text-white relative xl:bottom-28">
              <p className="text-center text-sm lg:text-base w-full w-700 m-auto pb-4 lg:pb-8">PT Ming Jaya Sejahtera adalah perusahaan yang membesarkan bisnis e-commerce khusus produk jam tangan. Dengan nama domain Jamtangan.com, e-commerce ini dinamai Machtwatch yang bermakna jam tangan yang tangguh.</p>
              <div className="block lg:flex text-white justify-center w-1/2 lg:w-6/12 m-auto space-x-0 lg:space-x-4">
                <div className="text-center w-full my-10 lg:my-0">
                  <h3 className="second-orange f-55 font-bold pb-3">250%</h3>
                  <span className="f-13 block">Pertumbuhan member website per tahun</span>
                </div>
                <div className="text-center w-full my-10 lg:my-0">
                  <h3 className="second-orange f-55 font-bold pb-3">60+</h3>
                  <span className="f-13 block">Brand jam tangan ternama</span>
                </div>
                <div className="text-center w-full my-10 lg:my-0">
                  <h3 className="second-orange f-55 font-bold pb-3">96%</h3>
                  <span className="f-13 block">Customer puas berbelanja online</span>
                </div>
              </div>
            </div> 
          </div>
        </Element>
        <Element name="tentang-kami" className="element" onClick={handleOnClick} onKeyDown={handleOnClick}>
          <br />
          <br className="hidden lg:block" />
          <div  className="overflow-x-hidden about-us relative bg-no-repeat bg-cover flex items-center bg-center bg-fixed mt-14 xl:mt-0" style={{backgroundImage: `url(${assets}/bg-about-us.webp)`}}>
            <div className="wrap-center text-center relative lg:bottom-20 pt-10 lg:pt-0 pb-52 lg:pb-0">
              <p className="font-bold pb-6 second-orange text-2xl">Tentang Kami</p>
              <span className="text-white text-base w-700 block m-auto">Kami berkomitmen menjadikan Machtwatch sebagai destinasi belanja nomor 1 di Indonesia untuk produk jam tangan. Machtwatch melayani customer dengan sepenuh hati melalui platform online maupun offline.</span>
            </div>
          </div>
        </Element>
        <Element name="keunggulan" className="element" onClick={handleOnClick} onKeyDown={handleOnClick}>
          <br />
          <br className="hidden lg:block" />
          <div className="overflow-x-hidden wrap-center-full relative text-white pb-4 lg:pb-24">
            <h3 className="second-orange text-2xl font-bold text-center my-16 mx-4">{items.benefit.title}</h3>
            <div className="flex lg:justify-center space-x-6 overflow-auto">
              {items.benefit.data.map((benefit, i) => {
                return (
                  <div key={i} className="content-list">
                    <img src={benefit.img} alt={benefit.title} width="190" height="190"/>
                    <p className="second-orange font-bold text-base mb-3">{benefit.title}</p>
                    <span className="f-13">{benefit.desc}</span>
                  </div>
                  )
                })
              }
            </div>
          </div>
          <div className="wrap-center-full py-8 lg:py-16">
            <h3 className="second-orange text-2xl font-bold text-center mb-8 lg:mb-16">{items.brand.title}</h3>
            <div className="hidden lg:block">
              <Slider {...settings}>
                {items.brand.data.map((brand, i) => {
                  return (
                    <a href={`https://www.jamtangan.com/shop/${brand.path}`} rel="noreferrer" target="_blank" className="outline-none shadow-none relative" key={i}>
                      <div className="flex items-center justify-center h-12 md:h-20 lg:h-24 border-solid border-2 border-white border-opacity-10 rounded-lg mx-2 mb-4 bg-brand hover:opacity-80">
                        <div className="absolute inset-x-0 top-0">
                          <label className={`${label(brand.status_highlight).classLabel} rounded-b-3xl m-auto text-center text-xs lg:h-5 w-50 flex justify-center items-center`}>{label(brand.status_highlight).textLabel}</label>
                        </div>
                        <img className="w-16 md:w-24 lg:w-28 m-auto" src={brand.icon} alt={brand.title} />
                      </div>
                    </a>
                  )
                })}
              </Slider>
            </div>
            <div className="block lg:hidden">
              <div className="flex overflow-auto">
                {items.brand.data.map((brand, i) => {
                  return (
                    <a href={`https://www.jamtangan.com/shop/${brand.path}`} rel="noreferrer" target="_blank" className="outline-none shadow-none relative content-list" key={i}>
                      <div className="flex items-center justify-center p-2.5 border-solid border-2 border-white border-opacity-10 rounded-lg mx-1 bg-brand hover:opacity-80">
                        <div className="absolute inset-x-0 top-0">
                          <label className={`${label(brand.status_highlight).classLabel} rounded-b-3xl m-auto text-center f-10 lg:h-5 w-50 flex justify-center items-center w-8 h-3.5`}>{label(brand.status_highlight).textLabel}</label>
                        </div>
                        <img className="w-16 md:w-24 lg:w-28 m-auto" src={brand.icon} alt={brand.title} />
                      </div>
                    </a>
                  )
                })}
              </div>
            </div>
          </div>
        </Element>
        <Element name="perjalan-kami" className="element" onClick={handleOnClick} onKeyDown={handleOnClick}>
          <br />
          <br />
            <div className="wrap-center-full overflow-x-hidden">
              <h3 className="second-orange text-2xl font-bold text-center my-8 lg:my-16">{items.trip.title}</h3>
              <div className="hidden xl:block">
                <VerticalTimeline>
                  {items.trip.data.map((trip, i) => {
                    return (
                      <VerticalTimelineElement
                        key={i}
                        className="vertical-timeline-element--work"
                        contentStyle={{ color: '#fff', backgroundImage: "url(" + trip.image + ")", backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}
                        contentArrowStyle={{ borderRight: '1px solid  rgb(33, 150, 243)' }}
                        date={trip.year}
                      >
                        <span className={trip.year ? "trip-title block pt-12 py-orange font-bold" : "trip-title block py-orange font-bold"}>{trip.title}</span>
                        <p className="trip-history">{trip.history}</p>
                      </VerticalTimelineElement>
                    )
                  })}
                </VerticalTimeline>
              </div>
              <div className="block xl:hidden overflow-x-hidden">
                {items.trip.data.map((trip, i) => {
                  return (
                    <div className="text-center" key={i}>
                      <p className="text-white opacity-50 font-bold leading-10 f-55 py-6">{trip.year}</p>
                      <img src={trip.image} alt={trip.title} className="w-full" />
                      <span className="block pt-12 pb-6 py-orange font-bold w-4/5 m-auto text-2xl">{trip.title}</span>
                      <p className="trip-history pb-12 text-white f-13 w-4/5 m-auto">{trip.history}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          </Element>
          <Element name="bisnis" className="element" onClick={handleOnClick} onKeyDown={handleOnClick}>
            <br />
            <br />
            <div className="bg-bussiness mt-20 lg:mt-28">
              <div className="wrap-center block lg:flex lg:flex-row-reverse lg:items-center lg:justify-center">
                <div className="w-full">
                  <img className="w-img-bussiness float-right relative bottom-9 lg:right-9" alt='Produk-Produk Mingjaya' src={`${assets}/jamtangan.png`} />
                </div>
                <div className="w-full py-14 lg:py-0">
                  <p className="second-orange text-2xl font-bold mb-6">Bisnis Kami</p>
                  <span className="block text-white text-sm lg:text-base lg:w-96 pb-10">Produk yang kami jual di Machtwatch meliputi jam tangan, watch box, watch winder, accessories, tas, dan perhiasan.</span>
                  <a href="https://www.jamtangan.com/" target="_blank" rel="noreferrer" className="rounded p-4 text-sm leading-5 text-black btn-sec-orange font-bold outline-none flex justify-center lg:inline opacity-90 hover:opacity-100">BELANJA SEKARANG</a>
                </div>
              </div>
            </div>
            <div className="overflow-x-hidden offline-store relative bg-no-repeat bg-cover flex items-center bg-center bg-fixed" style={{backgroundImage: `url(${assets}/bg-offline-store.webp)`}}>
              <div className="wrap-center-full">
                <div className="text-white block lg:flex lg:flex-row-reverse lg:items-center lg:justify-center">
                  <div className="lg:w-2/5 w-full pl-5 lg:pl-20 py-16 lg:py-0">
                    <p className="second-orange text-2xl font-bold mb-6">{items.store.title}</p>
                    <span className="block pt-4 text-base lg:w-96">{items.store.text}</span>
                    <span className="block pt-4 text-base lg:w-96">{items.store.text2}</span>
                  </div>
                  <div className="relative w-3/5 hidden lg:block">
                    <Slider {...store}>
                      {items.store.data.map((store, i) => {
                        return (
                          <div className="outline-none" key={i}>
                            <div className="m-2 border-solid lg:w-80 border-2 border-white border-opacity-10 rounded-lg focus:outline-none">
                              <img className="lg:w-80 h-auto rounded-t-lg" src={store.thumb} alt={store.title} />
                              <div className="p-4 mh-store opacity-87">
                                <p className="py-orange font-bold pb-4">{store.title}</p>
                                <span className="block f-13 opacity-87">{store.mall}</span>
                                <span className="block f-13 opacity-87">{store.address}</span>
                                <span className="block f-13 opacity-87 pb-4">{store.kota}</span>
                                <span className="block f-13 opacity-87">{store.phone}</span>
                                <span className="block f-13 opacity-87">{store.hour}</span>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </Slider>
                  </div>
                  <div className="block lg:hidden flex overflow-auto pb-4">
                    {items.store.data.map((store, i) => {
                      return (
                        <div className="outline-none content-list" key={i}>
                          <div className="m-2 border-solid lg:w-80 border-2 border-white border-opacity-10 rounded-lg focus:outline-none">
                            <img className="w-64 lg:w-80 h-auto rounded-t-lg" src={store.thumb} alt={store.title} />
                            <div className="p-4 mh-store opacity-87">
                              <p className="py-orange font-bold pb-4">{store.title}</p>
                              <span className="block f-13 opacity-87">{store.mall}</span>
                              <span className="block f-13 opacity-87">{store.address}</span>
                              <span className="block f-13 opacity-87 pb-4">{store.kota}</span>
                              <span className="block f-13 opacity-87">{store.phone}</span>
                              <span className="block f-13 opacity-87">{store.hour}</span>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Element>
          <Element name="karir" className="element" onClick={handleOnClick} onKeyDown={handleOnClick}>
            <div className="overflow-x-hidden bg-career pt-10">
              <div className="wrap-center block lg:flex lg:items-center lg:justify-center lg:flex-row-reverse py-16">
                <div className="w-full">
                  <img className="w-img-career float-right" alt="Berkarir di Ming Jaya" src={`${assets}/karir.png`} />
                </div>
                <div className="w-full pt-96 lg:pt-0">
                  <p className="second-orange text-2xl font-bold mb-6">Karir</p>
                  <span className="block text-white text-sm lg:text-base w-text-career pb-10">Inovasi dan kreativitas menjadi elemen penting dalam membangun bisnis kami. Hal ini juga yang memungkinkan kami mampu untuk membantu customer kami menemukan jam tangan yang sesuai dengan kriteria dan kebutuhan mereka. </span>
                  <a href="https://www.jamtangan.com/career/" rel="noreferrer" target="_blank" className="rounded p-4 text-sm leading-5 text-black btn-sec-orange font-bold outline-none flex justify-center lg:inline opacity-90 hover:opacity-100">BERGABUNG BERSAMA KAMI</a>
                </div>
              </div>
            </div>
          </Element>
          <Element name="kontak-kami" className="element" onClick={handleOnClick} onKeyDown={handleOnClick}>
            <div className="overflow-x-hidden bg-black-21">
              <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-6 text-white wrap-center-full py-8 lg:py-16">
                <div className="text-center lg:text-left">
                  <div className="border-btm-footer pt-4 lg:pt-0 pb-10 lg:pb-0">
                    <p className="second-orange text-base font-bold mb-6">Ikuti Kami di:</p>
                    <div className="ic-sosmed cs">
                      <div className="circle-icon-sosmed flex justify-center lg:justify-start">
                        <a href="http://www.instagram.com/machtwatch" target="_blank" rel="noreferrer" className="shadow-none opacity-50 hover:opacity-100"><img src={ig} alt="Instagram"/></a>
                        <a href="http://www.facebook.com/jamtangancom" target="_blank" rel="noreferrer" className="shadow-none mx-6 lg:mx-3 opacity-50 hover:opacity-100"><img src={fb} alt="Facebook"/></a>
                        <a href="http://www.youtube.com/channel/UClmXTLuCeHhY4X7TE0_fBPQ/featured" target="_blank" rel="noreferrer" className="shadow-none opacity-50 hover:opacity-100 mr-6 lg:mr-3"><img src={yt} alt="Youtube"/></a>
                        <a href="http://www.twitter.com/machtwatch" target="_blank" rel="noreferrer" className="shadow-none mr-6 lg:mr-3 opacity-50 hover:opacity-100"><img src={twt} alt="Twitter"/></a>
                        <a href="https://www.tiktok.com/@machtwatch/" target="_blank" rel="noreferrer" className="shadow-none opacity-50 hover:opacity-100"><img src={tiktok} alt="Tiktok"/></a>
                      </div>
                    </div>
                    <div className="pt-4 hidden lg:block">
                      <a href="https://blog.jamtangan.com/" rel="noreferrer" target="_blank" className="shadow-none opacity-50 hover:opacity-100 f-13">blog.jamtangan.com</a>
                    </div>
                    <div className="pt-8">
                      <a href="https://api.whatsapp.com/send?phone=6282288884040" rel="noreferrer" target="_blank" className="shadow-none bg-green p-3 rounded opacity-90 hover:opacity-100">
                        <img src={wa} alt="Whatsapp" className="inline-block pr-2"/>
                        <span className="f-13">Hubungi WhatsApp Kami</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="">
                  <p className="second-orange text-2xl font-bold mt-8 lg:mt-0 lg:mb-6 text-center lg:text-left">Download Apps</p>
                  <div className="block lg:flex">
                    <div className="hidden lg:block w-1/4">
                      <img src={Qrcode} className="w-full" alt="QRCode"></img>
                    </div>
                    <div className="block w-full lg:w-3/4 lg:pl-10">
                      <span className="block opacity-50 f-13 hidden lg:block"><strong className="font-black">Gratis Ongkir Tanpa Syarat</strong> untuk pembelian via mobile-app. Unduh aplikasi Jamtangan.com sekarang juga dan nikmati keuntungan tak terhingga! </span>
                      <div className="grid grid-cols-2 gap-3 pt-6 px-5 lg:px-0">
                        <a href="https://play.google.com/store/apps/details?id=com.machtwatch_mobile" target="_blank" rel="noopener noreferrer" className="shadow-none w-full">
                          <img src={playstore} className="w-full" alt="Play Store" />
                        </a>
                        <a href="https://itunes.apple.com/us/app/jamtangan-com/id1455081520" target="_blank" rel="noopener noreferrer" className="shadow-none w-full">
                          <img src={appstore} className="w-full" alt="App Store" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Element>
        <Footer></Footer>
      </>
      :
      <div className="loader absolute z-10 top-2/4 left-2/4 rounded-full"></div>
    }
    </Layout>
  );
}

export default Home

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
