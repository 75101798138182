import React from "react"

const Footer = () => {
  return (
    <footer className="text-center">
      <div className="bg-grey-2">
        <span className="block text-center text-white opacity-87 f-10 py-3">
          Copyright © 2020 PT. MING JAYA SEJAHTERA, All Rights Reserved.
        </span>
      </div>
    </footer>
  )
}

export default Footer
